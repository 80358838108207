@layer components {
  .site-footer {
    @apply bg-theme-neutral-light;
    &__container {
      @apply container gap-y-4 grid-cols-1 py-8 text-center;
    }

    &__logo {
      @apply border-b pb-4 w-full;

      &__link {
        @apply bg-center bg-contain bg-no-repeat bg-[image:var(--x-site-footer--logo)] block max-w-[var(--x-site-footer--logo--max-width)] h-9 mx-auto;
      }
    }

    &__nav {
      @apply border-b mx-auto pb-4 w-full;

      &__menu {
        @apply gap-2 grid justify-center text-center text-xs;
        @apply lg:flex;

        &__link {
          @apply link;
        }
      }
    }

    &__copyright {
      @apply text-center w-full;
    }

    &__disclaimer {
      @apply border-t pt-4 text-left text-xs w-full last:border-none last:pt-0;

      p {
        @apply relative;
        sup {
          @apply absolute top-0 left-[-5px];
        }
      }
    }
  }
}
