.page_name_steps-address {
  .form__fieldset__body {
    @apply relative;
    &:focus-within {
      @apply outline outline-2 outline-blue-600 outline-offset-2 rounded-lg z-10;

      .form__field {
        @apply z-10;
        box-shadow: 0px 0px 0px 2px #ffffff;
        .form__input {
          @apply border-b-0;
        }
      }
      .autocomplete--suggestions {
        @apply outline outline-2 outline-blue-600 outline-offset-2;
        box-shadow: 0px 0px 0px 2px #ffffff;
      }
    }
  }
  .form__field {
    @apply p-0 relative outline-none;
  }

  .autocomplete {
    &--suggestions {
      @apply bg-theme-neutral-background row-start-2 absolute border-0 border-t-0 border-theme-neutral max-h-64 rounded-b-lg z-[1] w-full;
    }

    &--suggestion {
      @apply bg-theme-neutral-background border-b border-b-theme-neutral-light cursor-pointer p-4 text-xs;
      @apply last:rounded-b-lg;
      @apply hover:bg-primary-background;
      @apply data-[is-active=true]:bg-primary-light data-[is-active=true]:text-primary-dark;
    }

    &--scrollbar {
      @apply overflow-y-scroll;
      &::-webkit-scrollbar {
        @apply w-1 rounded-br-lg bg-theme-neutral-background;
      }
      &::-webkit-scrollbar-thumb {
        @apply bg-theme-neutral rounded-lg;
      }
    }
  }

  .form__input_type_text {
    @apply pb-2 px-4 rounded-b-lg w-full;
  }

  .form__label {
    @apply pt-2 px-4;
  }

  .form__field__message {
    @apply m-0;
  }
}
